<template>
  <div style="width: 100% ;padding: 3%">
    <el-card class="box-card" style="width: 80%">
      <div slot="header" class="clearfix">
        <span>告示板</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="add">新增</el-button>
      </div>
<!--   告示板   -->
      <el-table
          :data="rulelist"
          style="width: 100%;"
          :show-header = false>
        <el-table-column
            prop="rules">
        </el-table-column>
        <el-table-column
            fixed="right"
            width="100">
          <template slot-scope="scope">
            <el-button @click="up(scope.row)" type="text" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm = "deleterow(scope.row)">
              <el-button type="text" size="small" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!--  弹窗  -->
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form :model="form" label-width="80px">
        <el-form-item label="告示板">
          <el-input v-model="form.rules" style="width: 90%"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false,this.gongxu=[]">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "ManagementSystem",

  data() {
    return {
      rulelist: [],
      ruleChanged: "",
      form:{},
      dialogVisible: false,
      search:'',
      total:10,
      upOrNew: '',
    }
  },
  //加载表格
  mounted() {
    this.select()
  },
  methods: {
    //新增
    add() {
      this.dialogVisible = true
      this.form = {}
      this.upOrNew = 'new'
    },
    //编辑表单回显
    up(row) {
      this.form = {
        rules: row.rules,
      }
      this.ruleChanged = row.rules
      this.dialogVisible = true
      this.upOrNew = 'up'
    },
    //删除按钮
    deleterow(row) {
      request.post("/DelManagementSystem", row.rules).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.select();
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //保存
    save() {
      if (this.upOrNew == 'new'){
        request.post("/SetManagementSystem",this.form.rules).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible = false
            this.rulelist.push({rules: this.form.rules});
            this.form = {}
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      } else if (this.upOrNew == 'up'){
        request.post("/UpdateManagementSystem",[this.ruleChanged,this.form.rules]).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: '编辑成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible = false
            this.form = {}
            this.select();
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }else {
        this.$notify.error({
          title: '错误',
          message: '意外的添加或修改'
        });
      }
    },
    //整表查询
    select(){
      request.post("/ManagementSystem").then(res =>{
        this.rulelist = []
        for (let i = 0; i < res.data.rules.length; i++) {
          this.rulelist.push({rules: res.data.rules[i]});
        }
      })
    },
    //编辑
    update() {
      this.form.classList = this.form.classList.toString()
      request.post("/SetManagementSystem", this.form).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.dialogVisible = false
          this.form = {}
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    handleEdit(row) {
    },
  }
}
</script>

<style scoped>

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}
</style>